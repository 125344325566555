import './bannerAlert.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class BannerAlert extends PureComponent {
  render() {
    const { isAlert } = this.props;
    const icon = `fas ${isAlert ? 'fa-exclamation-circle' : 'fa-check-circle'}`;
    const color = isAlert ? 'banner-alert-red' : 'banner-alert-green';

    return (
      <div className={`banner-alert ${color}`}>
        <p className='banner-alert_text'>
          {icon && <i className={`banner-alert_icon ${icon}`} />}
          {`${this.props.text}`}
        </p>
      </div>
    );
  }
}

BannerAlert.propTypes = {
  text: PropTypes.string.isRequired,
  isAlert: PropTypes.bool
};

export default BannerAlert;
