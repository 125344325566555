import './card.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

class Card extends PureComponent {
  render() {
    return (
      <LinkContainer to={this.props.link}>
        <a className='card' href={this.props.link}>
          <img className='card-background' title={this.props.title} alt='' src={this.props.image} />
          <div className='card-text'>
            <div className='card-title'>{this.props.title}</div>
          </div>
        </a>
      </LinkContainer>
    );
  }
}

Card.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string
};

export default Card;
