import './section.css';
import React, { PureComponent } from 'react';

class Section extends PureComponent {
  render() {
    const propsClass = this.props.className;

    return (
      <div className={`section` + (propsClass ? ` ${propsClass}` : '')}>
        {this.props.children}
      </div>
    );
  }
}

export default Section;
