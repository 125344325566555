import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

class Page extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>{this.props.title}</title>
        </Helmet>
        {this.props.children}
      </div>
    );
  }
}

Page.propTypes = {
  title: PropTypes.string
};

export default Page;
