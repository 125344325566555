import * as ACTION_TYPES from './actionTypes';

const initialState = {
  text: '',
  isAlert: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.AT_BANNER_ALERT_GET_ALERT_SUCCESS:
    {
      const { text, isAlert } = action.alert;
      return {
        ...state,
        text,
        isAlert
      };
    }
    case ACTION_TYPES.AT_BANNER_ALERT_GET_ALERT_FAILURE:
    default:
      return state;
  }
};
