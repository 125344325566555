import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import HomeReducer from './containers/home/reducer';
import DojoMapReducer from './containers/dojo-map/reducer';
import AlertReducer from './containers/banner-alert/reducer';

export default combineReducers({
  routing: routerReducer,
  home: HomeReducer,
  map: DojoMapReducer,
  alert: AlertReducer
});
