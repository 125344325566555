import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BannerAlert from '../../components/banner-alert';
import * as Actions from './actions';

export class BannerAlertContainer extends Component {
  componentDidMount() {
    this.props.getAlert();
  }

  renderAlert = () => {
    return (
      <BannerAlert {...this.props} />
    );
  };

  render() {
    if (this.props.text) {
      return this.renderAlert();
    } else {
      return null;
    }
  }
}

BannerAlertContainer.propTypes = {
  text: PropTypes.string.isRequired,
  isAlert: PropTypes.bool.isRequired,
  getAlert: PropTypes.func.isRequired
};

const mapStateToProps = state => ({...state.alert});

const mapDispatchToActions = dispatch => bindActionCreators(Actions, dispatch);

export default connect(mapStateToProps, mapDispatchToActions)(BannerAlertContainer);
