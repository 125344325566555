import React, { PureComponent } from 'react';
import Banner from '../../components/banner';
import Section, { SectionBlock } from '../../components/section';
import GoshinLogo from '../../components/logo';
import Page from '../../components/page';

import privateBanner from '../../images/private-banner.jpg';

class Private extends PureComponent {
  render() {
    return (
      <Page title='PRIVATE TRAINING | Goshin Ju-Jitsu'>
        <Banner image={privateBanner} fitViewport hasScrollIcon>
          <div className='training-banner-textbox'>
            <GoshinLogo />
          </div>
        </Banner>
        <Section>
          <SectionBlock header='PRIVATE JU-JITSU'>
            <p>&nbsp;As well as training at our clubs Goshin Ju Jitsu also offer tailored programmes of Women’s and General Self-Defence and Situation Awareness, private Ju-Jitsu lessons one-on-one or in small groups as well as special courses for schools, private individuals, professionals and businesses.</p>
            <p>&nbsp;These programmes can be provided at a time and place to suit you: at your workplace, home or any other suitable venue, taking all personal requirements into consideration. All levels from Beginners to Advanced welcome!</p>
            <p>&nbsp;Training programmes are tailored according to experience, age and physical fitness to ensure safe and enjoyable training.</p>
            <p>&nbsp;For more information and booking contact Kyoshi Jason on <a href='tel:+447763203948'>+44 (0) 7763 203-948</a> or email <a href='mailto:goshinjujitsu@hotmail.com'>goshinjujitsu@hotmail.com</a>.</p>
          </SectionBlock>
        </Section>
      </Page>
    );
  }
}

export default Private;
