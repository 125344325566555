import './cardContainer.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CardContainer extends PureComponent {
  render() {
    const { wrap, emptyLabel, children } = this.props;
    const empty = children.length <= 0;
    const emptyText = emptyLabel ? emptyLabel : 'NO DATA AVAILABLE';

    return (
      <div className={`card-container${wrap ? ' wrap' : ''}${empty ? ' empty' : ''}`}>
        {(children.length && children) || <p className='card-container-no-data'>{emptyText}</p>}
      </div>
    );
  }
}

CardContainer.propTypes = {
  wrap: PropTypes.bool,
  emptyLabel: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object)
};

export default CardContainer;
