import './dojoMap.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import uuid from 'uuid/v4';

class DojoMap extends PureComponent {
  renderMarkers = (markers) => {
    return markers.map((marker) => {
      return (
        <Marker key={uuid()} position={marker.coordinates}>
          {marker.name && <Popup>
            {marker.name && <b>{marker.name}</b>}
            {marker.address && marker.address.map(addrLine => <p key={uuid()} className='map-popup__text'>{addrLine}</p>)}
          </Popup>}
        </Marker>
      );
    });
  };

  render() {
    const { mapCSS, maxBounds, center, zoom, markers } = this.props;

    return (
      <Map id={uuid()} className={mapCSS} maxBounds={maxBounds} center={center} zoom={zoom} minZoom={zoom} scrollWheelZoom={false} zoomControl={false}>
        <TileLayer
          attribution="&amp;copy <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <ZoomControl position={'bottomright'} />
        {markers && this.renderMarkers(markers)}
      </Map>
    );
  }
}

DojoMap.propTypes = {
  mapCSS: PropTypes.string,
  maxBounds: PropTypes.array,
  center: PropTypes.array,
  markers: PropTypes.array,
  zoom: PropTypes.number
};

export default DojoMap;
