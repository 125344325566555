import './junior.css';
import React, { PureComponent } from 'react';
import Banner from '../../components/banner';
import Section, { SectionBlock } from '../../components/section';
import Image from '../../components/image';
import GoshinLogo from '../../components/logo';
import Page from '../../components/page';

import juniorBanner from '../../images/junior-banner.jpg';
import juniorLecture from '../../images/junior-lecture.jpg';

class Junior extends PureComponent {
  render() {
    return (
      <Page title='JUNIOR TRAINING | Goshin Ju-Jitsu'>
        <Banner image={juniorBanner} fitViewport hasScrollIcon>
          <div className='training-banner-textbox'>
            <GoshinLogo />
          </div>
        </Banner>
        <Section>
          <SectionBlock header='GOSHIN KIDS'>
            <p>&nbsp;Nowadays you cannot be too careful in a society where knife crime and muggings seem to be on the daily agenda. Learn how to defend yourself and how to deal with a dangerous situation without getting hurt. Become more aware of your surroundings and learn to judge a situation correctly.</p>
            <Image image={juniorLecture} imageAlt='Goshin Juniors Lecture' caption='Goshin Juniors being lectured by Kyoshi Jason' />
            <p>&nbsp;Regular training in Ju Jitsu offers many benefits, physical and mental:</p>
            <ul>
              <li>Improved Fitness and Physical Ability / Coordination</li>
              <li>Growing in Self-Confidence and Self-Esteem</li>
              <li>Increased value of Respect and Discipline</li>
              <li>Better Concentration at school / Mental Focus</li>
              <li>Friendship and being part of our Ju Jitsu Family</li>
              <li>Learning about Loyalty and Commitment</li>
            </ul>
            <p>&nbsp;Feel confident and be less of a target at school and in the street! Don&#39;t put up with being picked on! These skills can also save your life!</p>
            <p>&nbsp;We also do Anti-Bullying and Self-Defence with Situational Awareness courses for children, ask Kyoshi Jason anytime or call <a href='tel:+447763203948'>+44 (0) 7763 203-948</a>.</p>
          </SectionBlock>
          <SectionBlock header='BEGINNER CLASSES'>
            <p>&nbsp;We believe that the beginner’s classes are a very important step for your child, which will open doors to learn what Ju Jitsu is about and how it will benefit them in their own development, teaching them about their personal capabilities.</p>
            <p>&nbsp;They will learn the basics of self-defence, how to fall if involved in an attack and how to defend oneself on the ground should such occasion occur. This will improve their self-confidence, and awareness of their surroundings.</p>
            <p>&nbsp;One of our aims is to teach our students to work in pairs with their training partners, setting a good example and helping lower grades with their practice of any techniques (within the parameter of their current syllabus).</p>
            <p>&nbsp;This will give your child the benefit of:</p>
            <ul>
              <li>Gaining Maturity and Self-Control</li>
              <li>Taking Responsibility</li>
              <li>Improving Self-Confidence when dealing with other children and adults</li>
              <li>Improved Communication / Listening Skills</li>
              <li>Leadership Skills and a positive attitude</li>
              <li>Respect and Determination</li>
              <li>Team Work and Cooperation</li>
            </ul>
            <p>&nbsp;All students develop at their own pace, some take longer than others. What&#39;s most important is that they enjoy their journey and reach their personal potential when they are ready. Fact is that 100% of children, whotrain in a Martial Art regularly, improve their abilities and performance at school.</p>
          </SectionBlock>
        </Section>
      </Page>
    );
  }
}

export default Junior;
