import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Home from '../../components/home';
import * as Actions from './actions';

export class HomeContainer extends Component {
  componentDidMount() {
    this.props.getSeminars();
  }

  render() {
    return (
      <Home {...this.props} />
    );
  }
}

HomeContainer.propTypes = {
  seminars: PropTypes.array.isRequired,
  getSeminars: PropTypes.func.isRequired
};

const mapStateToProps = state => ({...state.home});

const mapDispatchToActions = dispatch => bindActionCreators(Actions, dispatch);

export default connect(mapStateToProps, mapDispatchToActions)(HomeContainer);
