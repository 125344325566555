import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './animations.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import Store, { history } from './redux-store';

import NavBar from './components/navbar';
import Footer from './components/footer';
import HomeContainer from './containers/home';
import About from './components/about';
import Junior from './components/junior';
import Adult from './components/adult';
import Private from './components/private';
import FindDojo from './components/find-dojo';
import Gallery from './components/gallery';
import Terms from './components/terms';
import Instructors from './components/instructors';
import Unknown from './components/unknown';
import BannerAlertContainer from './containers/banner-alert';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={Store}>
    <ConnectedRouter history={history}>
      <div>
        <NavBar />
        <BannerAlertContainer />
        <Switch>
          <Route exact path='/' component={HomeContainer} />
          <Route path='/about' component={About} />
          <Route path='/junior' component={Junior} />
          <Route path='/adult' component={Adult} />
          <Route path='/private' component={Private} />
          <Route path='/find-dojo' component={FindDojo} />
          <Route path='/instructors' component={Instructors} />
          <Route path='/gallery' component={Gallery} />
          <Route path='/terms' component={Terms} />
          <Route component={Unknown} />
        </Switch>
        <Footer />
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
