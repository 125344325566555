import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DojoMap from '../../components/dojo-map';
import * as Actions from './actions';

export class DojoMapContainer extends Component {
  componentDidMount() {
    this.props.getDojos();
  }

  render() {
    return (
      <DojoMap {...this.props} />
    );
  }
}

DojoMapContainer.propTypes = {
  markers: PropTypes.array.isRequired,
  getDojos: PropTypes.func.isRequired,
  mapCSS: PropTypes.string,
  maxBounds: PropTypes.array,
  center: PropTypes.array,
  zoom: PropTypes.number
};

const mapStateToProps = state => ({...state.map});

const mapDispatchToActions = dispatch => bindActionCreators(Actions, dispatch);

export default connect(mapStateToProps, mapDispatchToActions)(DojoMapContainer);
