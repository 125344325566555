import './button.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Button extends PureComponent {
  render() {
    return (
      <div className='button' onClick={this.props.onClick}>{this.props.label}</div>
    );
  }
}

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default Button;
