import './imageModal.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Button from '../button';

class ImageModal extends PureComponent {
  onContextMenu = (e) => {
    if (!this.props.enableContextMenu) e.preventDefault();
  };

  render() {
    return (
      <Modal show={this.props.show} onContextMenu={this.onContextMenu}>
        <Modal.Body>
          <img src={this.props.image} title='' alt='' />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onClose} label='CLOSE' />
        </Modal.Footer>
      </Modal>
    );
  }
}

ImageModal.props = {
  show: PropTypes.bool.isRequired,
  enableContextMenu: PropTypes.bool,
  image: PropTypes.string,
  onClose: PropTypes.func
};

export default ImageModal;
