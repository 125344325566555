import './footer.css';
import React, { PureComponent } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Version from '../version';
import Copyright from '../copyright';

import badge_CombatJujitsu from '../../images/badge-ch-m.png';
import badge_Jindokai from '../../images/badge-j-m.png';
import badge_ZNSBR from '../../images/badge-znsbr-m.png';
import badge_KenpoJutsu from '../../images/badge-kjagb-m.png';

class Footer extends PureComponent {
  render() {
    return (
      <div className='footer'>
        <div className='footer-block'>
          <div className='footer-nav-column'>
            <ul>
              <li><LinkContainer to='/'><p>Goshin Ju-Jitsu</p></LinkContainer></li>
              <li><LinkContainer to='/about'><p>About</p></LinkContainer></li>
              <li><LinkContainer to='/junior'><p>Junior</p></LinkContainer></li>
              <li><LinkContainer to='/adult'><p>Adult</p></LinkContainer></li>
              <li><LinkContainer to='/private'><p>Private</p></LinkContainer></li>
              <li><LinkContainer to='/gallery'><p>Gallery</p></LinkContainer></li>
              <li><LinkContainer to='/find-dojo'><p>Find Dojo</p></LinkContainer></li>
              <li><LinkContainer to='/terms'><p>Terms & Privacy</p></LinkContainer></li>
            </ul>
          </div>
          <div className='footer-nav-column'>
            <ul>
              <li>Social Media</li>
              <li>
                <a
                  href='https://www.twitter.com/goshin_jujitsu'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fab fa-twitter' /> Twitter
                </a>
              </li>
              <li>
                <a
                  href='https://www.facebook.com/pages/Goshin-Ju-Jitsu-Association-GB-london/432276686841053?ref=ts&fref=ts'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fab fa-facebook-square' /> Facebook
                </a>
              </li>
              <li>
                <a
                  href='https://www.instagram.com/goshinjujitsu1/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='fab fa-instagram' /> Instagram
                </a>
              </li>
            </ul>
          </div>
          <div className='footer-nav-column'>
            <ul>
              <li>Contact</li>
              <li>p: <a href='tel:+447763203948'>+44 (0) 7763 203-948</a></li>
              <li>e: <a href='mailto:goshinjujitsu@hotmail.com'>goshinjujitsu@hotmail.com</a></li>
            </ul>
          </div>
        </div>
        <div className='footer-block end'>
          <span className='footer-alliance'>
            <img className='footer-alliance-img' title='Hellas Combat JuJitsu' alt='Hellas Combat Ju Jitsu' src={badge_CombatJujitsu} />
            <img className='footer-alliance-img' title='Jindokai' alt='Jindokai' src={badge_Jindokai} />
            <img className='footer-alliance-img' title='All Japanese Budo Federation' alt='ZNSBR' src={badge_ZNSBR} />
            <img className='footer-alliance-img' title='Kenpo Jutsu Alliance GB' alt='Kempo Jutsu Alliance GB' src={badge_KenpoJutsu} />
          </span>
          <Copyright />
          <Version />
        </div>
      </div>
    );
  }
}

export default Footer;
