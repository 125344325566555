import './findDojo.css';
import React, { PureComponent } from 'react';

import Section, { SectionBlock } from '../../components/section';
import Schedule from '../../components/schedule';
import Page from '../../components/page';
import DojoMapContainer from '../../containers/dojo-map';

class FindDojo extends PureComponent {
  render() {
    return (
      <Page title='FIND DOJO | Goshin Ju-Jitsu'>
        <Section className='map-section'>
          <DojoMapContainer mapCSS='find-dojo__map' maxBounds={[[52,-0.7],[51.5,0.3]]} center={[51.74, -0.07]} zoom={10} />
          <SectionBlock header='SCHEDULE'>
            <Schedule title='Orchard Dojo' subtitle='Grange Park Methodist Church, Old Park Ridings, London, N21 2EU'>
              <div className='schedule-col desktop'>
                <h5>Day</h5>
                <ul>
                  <li>Monday</li>
                </ul>
              </div>
              <div className='schedule-col desktop'>
                <h5>Group</h5>
                <ul>
                  <li> Junior </li>
                  <li> Adult </li>
                </ul>
              </div>
              <div className='schedule-col desktop'>
                <h5>Time</h5>
                <ul>
                  <li> 18:00 - 19:00 </li>
                  <li> 19:45 - 20:45 </li>
                </ul>
              </div>
              <div className='schedule-col mobile'>
                <h5>Junior</h5>
                <ul>
                  <li><p>Monday</p><p> 18:00 - 19:00 </p></li>
                </ul>
              </div>
              <div className='schedule-col mobile'>
                <h5>Adult</h5>
                <ul>
                  <li><p>Monday</p><p> 19:45 - 20:45 </p></li>
                </ul>
              </div>
            </Schedule>
            <Schedule title='Potters Bar Dojo' subtitle='Oakmere Community Centre, Fetherstone Close, Potters Bar, EN6 5NS'>
              <h5>Closed</h5>
            </Schedule>
          </SectionBlock>
        </Section>
      </Page>
    );
  }
}

export default FindDojo;
