import * as ACTION_TYPES from './actionTypes';

function fetchAlertSuccess(responses) {
  let alert = {};
  if (responses && Array.isArray(responses)) {
    alert = responses.shift();
  }

  return {
    type: ACTION_TYPES.AT_BANNER_ALERT_GET_ALERT_SUCCESS,
    alert
  }
}

function fetchAlertFailure(error) {
  return {
    type: ACTION_TYPES.AT_BANNER_ALERT_GET_ALERT_FAILURE,
    error
  }
}

export function getAlert() {
  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_API_URL}alert`)
      .then(res => res.json())
      .then(res => {
        if (res.status === false) {
          throw res;
        }

        dispatch(fetchAlertSuccess(res.results));
      })
      .catch(error => {
        dispatch(fetchAlertFailure(error));
      });
  };
}
