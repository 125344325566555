import * as ACTION_TYPES from './actionTypes';

const initialState = {
  seminars: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.AT_HOME_GET_SEMINARS_SUCCESS:
    {
      return {
        ...state,
        seminars: action.seminars
      };
    }
    case ACTION_TYPES.AT_HOME_GET_SEMINARS_FAILURE:
    default:
      return state;
  }
};
