import React, { PureComponent } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Section, { SectionBlock } from '../../components/section';
import Page from '../../components/page';

class Terms extends PureComponent {
  render() {
    return (
      <Page title='TERMS | Goshin Ju-Jitsu'>
        <Section>
          <Tabs id='terms-tab-controller'>
            <Tab title='DOJO RULES' eventKey={1}>
              <SectionBlock header='UNIFORM'>
                <p>&nbsp;The Ju Jitsu uniform is know by its Japanese name ‘gi’ and comprises of a re-inforced jacket, trousers and a belt. The principle being that everyone wears the same uniform and the only distinguishing markings are earned, in the form of coloured belts.</p>
                <p>&nbsp;All members are required to wear the Goshin Ju Jitsu badge on the front of their gi as well as any additional badge of any of our affiliated Clubs and Associations.</p>
                <p>&nbsp;The belts in order are:</p>
                <ol>
                  <li>White</li>
                  <li>Red</li>
                  <li>Yellow</li>
                  <li>Orange</li>
                  <li>Green #1</li>
                  <li>Green #2</li>
                  <li>Blue</li>
                  <li>Purple</li>
                  <li>Brown #1</li>
                  <li>Brown #2</li>
                  <li>Brown #3 <i>(Juniors only)</i></li>
                  <li>Black</li>
                </ol>
                <p>&nbsp;Beginners wear loose clothes, often a t-shirt and track suit trousers, however they often quickly opt to purchase a hard wearing gi. Beginners wear a white belt.</p>
              </SectionBlock>
              <SectionBlock header='MEMBER RESPONSIBILITY'>
                <p>&nbsp;Every member has a responsibility to ensure the safety of themselves and of others. You must comply with the following Requirements and Membership Rules and the rules and Etiquette of the Dojo of Goshin Ju Jitsu Association GB at all times.</p>
                <p>&nbsp;It must be borne in mind that Ju Jitsu and Mixed Martial Arts is a contact sport, which involves acceptance of a certain risk of occasional injuries, such as bruises and sprains. This applies to both, attackers and defenders however, it is the main aim of Goshin Ju Jitsu Association GB to provide a safe environment and train in a safe manner under suitable supervision and control.</p>
                <br></br>
                <h5><b>MEMBERSHIP REQUIREMENTS</b></h5>
                <ul>
                  <li>Apply for Goshin Ju Jitsu Membership within two week of commencing training.</li>
                  <li>Apply for insurance within two week of commencing training. *</li>
                  <li>Maintain a valid Ju Jitsu membership status throughout the entire period of training with Goshin Ju Jitsu Association GB.</li>
                  <li>Set up payment by Standing Order to cover all mat fees in advance on the 1st of each month. **</li>
                  <li>Give 1 month notice to terminate membership as required.</li>
                  <li>Disclose any medical conditions, previous injuries or changes in their health status, which may prevent or affect their training in Ju Jitsu when completing our membership form as well as any information on special medical requirements.</li>
                  <li>Disclose any criminal offences due to violence in the past.</li>
                </ul>
                <br></br>
                <p><i>* Insurance is underwritten by Shuron Keith of the Kemp Ju Jitsu Association, in association with Goshin Ju Jitsu Association GB.</i></p>
                <p><i>** 1. Membership includes all deductions necessary in the monthly fee, including all bank holidays and the August and Christmas break from training. 2. Cancelling the standing order is interpreted as a student cancelling their membership thus, no longer valid for training.</i></p>
                <br></br>
                <p><i>Note: Lessons may be cancelled at short notice to the discretion of the Sensei. This is a condition agreed upon by obtaining membership with the association. No refunds are given for cancellations unless stated otherwise by the Sensei.</i></p>
                <br></br>
                <h5><b>MAT ETIQUETTE</b></h5>
                <ul>
                  <li>Bow on entry and exit to the dojo</li>
                  <li>Ask permission before stepping onto the mat</li>
                  <li>Ask permission to leave the mat & dojo</li>
                  <li>No footwear must be worn whilst on the mat</li>
                  <li>Clean feet, short finger and toe nails</li>
                  <li>Not smoke, drink, swear or any inappropriate behaviour – treat the dojo with respect</li>
                </ul>
                <br></br>
                <h5><b>DOJO ETIQUETTE</b></h5>
                <p>&nbsp;Every member must obey the rules of Goshin Ju Jitsu Association GB and the Etiquette of the Dojo and ensure that they will:</p>
                <ul>
                  <li>Maintain a high standard of personal hygiene.</li>
                  <li>Wear a clean gi and keep it in a good condition.</li>
                  <li>Not consume food, including chewing gum, in the dojo and leave a suitable period between eating and the start of training.</li>
                  <li>Not consume alcohol or any other substances, which may affect their state of mind, prior to and throughout training and as long as in the vicinity of the dojo.</li>
                  <li>Not to smoke anywhere in the vicinity of the dojo or whilst wearing a gi.</li>
                  <li>Keep toe and finger nails as short as possible.</li>
                  <li>Remove any type of jewelry, such as watches, bracelets, rings or hair pins during training to reduce the risk of causing or suffering related injuries (stud earrings must be covered completely on both sides with a plaster).</li>
                  <li>Follow the instructions of the Instructor or his nominee.</li>
                  <li>Not leave the mat or dojo during training sessions without notifying the Club Instructor.</li>
                  <li>Follow and respect the advice and instructions of other more experienced members where that advice relates to their safety or training syllabus.</li>
                  <li>Not attempt techniques, which they have not been instructed to do so.</li>
                  <li>Not engage in free practice without the presence of an Instructor.</li>
                  <li>Stop training and/or move away if they become aware of any blood spillage, informing an instructor without any delay, so that it can be treated urgently.</li>
                  <li>Inform the Instructor of anything, which may affect the safety of any student.</li>
                  <li>Not use full power kicks or punches to the head or heart.</li>
                  <li>Use only appropriate force when training and exercise restraint where required, particularly when applying strangles and locks.</li>
                  <li>Maintain any personal training equipment or weapons, which may be used within the club, in a safe condition.</li>
                  <li>Carry any weapons, particularly bo’s, jo’s, bokkens, nunchucks in suitable cases and avoid the attention of the public.</li>
                  <li>Not carry any illegal weapons in their training bags by all means.</li>
                  <li>Observe dojo rules and etiquette and ensure that the defender is ready prior to commencing training or an attack.</li>
                </ul>
              </SectionBlock>
              <SectionBlock header='WORDS'>
                <br></br>
                <h4>DOJO</h4>
                <p>&nbsp;This is the room where you train. It can be a church hall, sports hall, squash court, but when the mats are down, the room becomes a dojo and correct rules apply. The dojo floor is covered with reinforced mats known as tatami; used to absorb impact of falls.</p>
                <br></br>
                <h4>REI</h4>
                <p>&nbsp;This is the name given to the traditional Japanese bow or salutation, similar to the Western shaking hands. The bow should be performed correctly. The standing bow is performed with feet together, bending forwards at the waist with alignment of neck and back, hands move down the front of the legs until the body forms approx 30 degree angle.</p>
                <p>&nbsp;The kneeling bow is more formal, and is performed at the beginning and end of the training session, marking the start and finish of training.</p>
                <br></br>
                <h4>SENSEI</h4>
                <p>&nbsp;This term is the Japanese name for instructor. All instructors are referred to as Sensei. Instructors are those that hold the rank of black belt and above.</p>
                <br></br>
                <h4>YAMAE</h4>
                <p>&nbsp;Means stop or halt, and must be adhered to immediately for safety reasons.</p>
              </SectionBlock>
            </Tab>
            <Tab title='TERMS & CONDITIONS' eventKey={2}>
              <SectionBlock header='SAFEGUARDING CHILDREN'>
                <p>&nbsp;Goshin Ju Jitsu support the premise that children and vulnerable adults who may attend classes should expect that the instructors are of the highest integrity and will behave in a professional and appropriate manner. Parents have the right to expect that their children will come to no harm at the hands of instructors. The following is not exclusive, but covers many of the issues that Goshin feel should be addressed.</p>
                <p>&nbsp;Goshin Ju Jitsu Association GB recognize that it is inevitable for instructors of Martial Arts to make physical contact with all students in the class, including children, which is the nature of the activity. However, instructors are fully aware what constitutes inappropriate contact and that they should not conduct themselves in ways, which could be considered unprofessional.</p>
                <p>&nbsp;Instructors also need to be sensitive to the needs of the children attending the class, not to intimidate or force a child to attempt any technique, which they are clearly unable to master. Similarly, bullying by other students will not be tolerated. Goshin Ju Jitsu Association GB instructors are aware of the issues surrounding Child Protection and do their professional best to ensure the standards are maintained. Instructors will take appropriate action where a student is involved in bullying or intimidation of or by another student.</p>
              </SectionBlock>
              <SectionBlock header='BACKGROUND CHECKS'>
                <p>&nbsp;All our Senior Instructors at Goshin Ju Jitsu Association GB have trained in Ju Jitsu for a number of years, having achieved the level of black belt 1st dan or higher. All instructors in charge of our Association have a history of service to their club and the Association and are well known and respected members.</p>
                <p>&nbsp;No-one with an unsuitable background will be allowed to teach at Goshin Ju Jitsu Association GB.</p>
              </SectionBlock>
              <SectionBlock header='DUTY OF CARE'>
                <ul>
                  <li>Instructors must respect the rights, dignity and worth of every person and treat everyone equally within the context of Ju Jitsu.</li>
                  <li>Instructors must place the safety and well being of the student above the development or performance.</li>
                  <li>Instructors must hold up-to-date qualifications, a recognized First Aid certificate and valid Insurance Cover (currently with AMA, Amateur Martial Art).</li>
                  <li>Instructors must develop an appropriate working relationship with students (especially children), based on mutual trust and respect.</li>
                  <li>Instructors must not exert undue influence to obtain personal benefit or reward.</li>
                  <li>Instructors must encourage and guide students to accept responsibility for their own behaviour and performance.</li>
                  <li>Instructors must ensure the activities they direct are appropriate for the age, maturity experience and ability of the individual.</li>
                  <li>Instructors should, at the outset, clarify with students (and where appropriate parents) exactly what is expected of them and what students are entitled to expect from their Instructor.</li>
                  <li>Instructors should always promote the positive aspects of Ju Jitsu, such as etiquette and discipline) and never condone violations or the use of prohibited substances.</li>
                  <li>Instructors must consistently display high standards of behaviour and appearance.</li>
                </ul>
              </SectionBlock>
              <SectionBlock header='PRE-TRAINING CHECKS'>
                <ul>
                  <li>A First Aider, emergency telephone and first aid kit are available and venue emergency procedures are known.</li>
                  <li>All participants must hold or have applied for a valid membership, within two weeks of starting, having given information on any medical condition, which may affect training.</li>
                  <li>Environmental conditions are suitable. Lighting, ventilation, heating, dojo is in a safe condition.</li>
                  <li>Mats have been set out correctly and are in a sound condition.</li>
                  <li>Any furniture, personal belongings, bags, etc. do not obstruct exits or present trip hazards.</li>
                  <li>Participants are aware of Dojo rules, etiquette, safety precautions and are wearing suitable clothing in a good state of repair.</li>
                  <li>Suitable warm-up exercises have been completed.</li>
                  <li>Weapons and training equipment are in a safe condition, including personal training equipment.</li>
                  <li>Beginners or visiting members are made known to the Club Instructor.</li>
                  <li>Parents are allowed to stay and watch their children at the Instructor’s discretion.</li>
                </ul>
              </SectionBlock>
              <SectionBlock header='TRAINING DUTIES'>
                <ul>
                  <li>Injuries and blood spillages are treated immediately and recorded in the relevant accident book. The Premises Ownershall be notified if any accident results in any incident requiring medical attention.</li>
                  <li>The mat shall be kept free of gaps and clear of training equipment as far as possible. Where necessary adequate space shall be maintained between students or groups whilst on the mat.</li>
                  <li>Persons feeling faint or nauseous shall be accompanied when going to the toilet or changing rooms.</li>
                  <li>Training is tailored to individual ability.</li>
                  <li>Training weapons are inspected before use.</li>
                  <li>Appropriate control and restraint is exercised in relation to the application of techniques. Kicks or punches to the head, heart or groin should be controlled. Full power finishing off and weakening strikes are not permitted.</li>
                </ul>
              </SectionBlock>
              <SectionBlock header='POST-TRAINING CHECKS'>
                <ul>
                  <li>All injuries have been recorded.</li>
                  <li>Defective equipment shall be removed or reported.</li>
                  <li>Mats are carried and stored correctly.</li>
                  <li>The Dojo shall be left in a clean and tidy condition.</li>
                </ul>
              </SectionBlock>
            </Tab>
            <Tab title='PRIVACY & GDPR' eventKey={3}>
              <SectionBlock>
                <p>&nbsp;The domain <i>goshinju-jitsu.co.uk</i> stores no cookies or tracking data. Google Analytics is not used on this site or any other analytic services.</p>
                <p>&nbsp;Data is not collected or stored from users and there is no way to enter personal information on this site thus; meets the requirements for the EU directive GDPR.</p>
              </SectionBlock>
            </Tab>
            <Tab title='DISCLAIMER' eventKey={4}>
              <SectionBlock>
                <p>&nbsp;All information contained in this website is for the purpose of general information only. The information is provided by Goshin Ju Jitsu Association GB. We endeavour to keep all information correct and up to date. However, we make no representations or warranties of any kind about the accuracy, completeness, reliability, suitability or availability with respect to the website and its contents for any purpose. Any reliance you place on such information is therefore stricty at your own risk.</p>
                <p>&nbsp;In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                <p>&nbsp;Through this website you are able to link to other websites which are not under the control of Goshin Ju Jitsu Association GB. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                <p>&nbsp;Every effort is made to keep the website up and running smoothly. However, Goshin Ju Jitsu Association GB takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
                <p>&nbsp;Lastly, and very important, every technique, position or other type of Goshin Ju Jitsu advice you see through this web site is, and should always be, supervised by an experienced Ju-Jitsu coach, instructor or professor. We strongly discourage the practice of Ju-Jitsu without the supervision and oversight of an experienced Ju-Jitsu coach, instructor or professional and by no means will we take responsibility for any injuries recurring from unsupervised amateur training or competition.</p>
              </SectionBlock>
            </Tab>
          </Tabs>
        </Section>
      </Page>
    );
  }
}

export default Terms;
