import React, { PureComponent } from 'react';
import { SectionBlock } from '../../../components/section';
import Image from '../../../components/image';

import goshinScotland from '../../../images/goshin_scotland.jpg';

class Club extends PureComponent {
    render() {
      return (
        <div>
          <SectionBlock header='GOSHIN'>
            <p>&nbsp;The Goshin Ju-Jitsu Association GB is a multi-disciplined team of Instructors teaching a variety of Eastern and Western Martial Arts. These range from boxing techniques to Juko Ryu Ju-Jitsu, freestyle grappling, Gendai Ju Jitsu (grappling), Brazilian Jiujitsu as well as Weapon training and many others. Our Instructors are fully qualified, CRB checked and First Aid trained.</p>
            <p>&nbsp;In addition to our range of weekly, scheduled classes, private self-defence classes are available for those seeking self-improvement or even just as a form of self-protection. Whatever your reason for studying the Martial Arts, we are confident that we can accommodate you to achieve your personal goal.</p>
            <Image image={goshinScotland} imageAlt='Goshin in Scotland' caption='Goshin at a Yotoshinkai Karate seminar (Scotland 2017)' />
            <p>&nbsp;The association also has professional links with other Martial artists who are experts in other styles; our intention is to offer Master classes in these other arts to our students, should they wish to partake of this wealth of experience. This is in the form of Seminars held all over the UK and Abroad. We are an open minded Assocication and are always happy to invite Instructors and students from other disciplines.</p>
            <p>&nbsp;Martial Arts, as the name would imply, have long been viewed as part sport, and part art form. Martial Arts run the full length of the spectrum from professional-level sports to artistic demonstrations, from such styles as Lau Gar Kung Fu Wing Chun or Tai Chi, which are similar in style as to imitate dance, but none the less effective once the skills are mastered. Innumerable people derive a vast amount of learning and pleasure from the “art” facet of Martial Arts, either as spectators or participants.</p>
            <p>&nbsp;The Martial Arts are widely recognised as offering far more than purely physical benefits. The Goshin Ju-Jitsu Association GB believes that studying the Martial Arts improves both learning skills and concentration, this can dramatically affect children’s and young people’s behaviour in school, and in society in general, this has been repeatedly proven by the numerous workshops / courses we have run within various boroughs in London. The impact on young adults and adults provides a fresh outlook on the way they work and learn. The organisation’s Instructors believe that their teaching and motivational skills have a very positive effect on the students’ lives outside of classes.</p>
            <p>&nbsp;Traditionally, Martial Arts have been viewed as male-dominated sports practised by particular socio-economic groups. This is changing; everyone from housewives to students to City workers to OAPs and juniors are all taking an active interest in the physical and emotional benefits that Martial Arts can bring. These new markets expect more: high quality, reliable service, knowledgeable Instructors, and value for money. The Goshin Ju-Jitsu Association GB recognise this and reflects this knowledge in its development plans. Our Instructors hail from a variety of backgrounds and experiences, and we draw upon their skills to offer the best possible service to our professional clients and our students.</p>
            <p>&nbsp;In 2012 Hanshi Stephen Chan, 9th dan, OBE, BA, MA, PhD, established his own Jindokai organisation – Jindokai International Federation – with dojos all over the world and invited me to join him as a Board member and also to become Head of Goshin Jindokai Ju Jitsu in addition to being Head Instructor of my own association.</p>
          </SectionBlock>
          <SectionBlock header='MISSION'>
            <p>&nbsp;The chief aim of the Association is to provide a safe and friendly environment for members to learn Martial Arts and have fun in the process, with emphasis on a strong sense of morality and traditional Japanese etiquette.</p>
            <p>&nbsp;Historically, we have worked both nationally and internationally with various voluntary and statutory organisations, holding Self-Defence workshops and Seminars. We have extensive knowledge and experience in providing Close Protection Services and Close Protection Training.</p>
            <p>&nbsp;Our mission is to build good partnerships between students, instructors and parents of our Junior students, offering multiple benefits to the community, including self-development through personal training, instructions on fitness & wellbeing, all which helps to instil an edge of self-confidence, respect and determination throughout all age groups.</p>
            <p>&nbsp;Our club is very much attuned to adults as well as children and their families within the group, developing strong friendships outside Goshin Ju Jitsu as a result.</p>
            <p>&nbsp;We provide the opportunity for every individual to achieve their full potential in the art of traditional Japanese and Goshin Ju Jitsu.</p>
            <p>&nbsp;In order to achieve this, we:</p>
            <ul>
              <li>Follow an Equal Rights Policy, ensuring an equal opportunity for every student to study and perform Ju Jitsu to the best of their ability, irrespective of gender, ethnicity and educational background. Members with disabilities are also welcome, depending on the severity of the disability.</li>
              <li>Provide a friendly and welcoming environment for everyone, monitoring a personal approach to their technical, physical and social development with emphasis on fair-play, respect and abiding by the dojo rules.</li>
              <li>Aim to build links with schools and Sport Development Programmes, responding to changes in National Governing Bodies in order to guarantee teaching of the highest standard possible.</li>
              <li>Agree to honour non-Goshin grades previously attained, subject to verification with the precondition that a student must present himself/herself for a Goshin Ju Jitsu grading to assess his/her grade.</li>
              <li>Support students with the freedom to train in other styles of Martial Arts, Associations or dojos to broaden their skills and knowledge.</li>
            </ul>
          </SectionBlock>
        </div>
      );
    }
}

export default Club;
