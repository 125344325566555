import './event.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

class EventCard extends PureComponent {
  render() {
    const { local, grading, start, end, title, file } = this.props;
    const backgroundIconCSS = `fas ${grading ? 'fa-graduation-cap' : local ? 'fa-car' : 'fa-plane-departure'} fa-8x event__icon-background`;
    const download = file !== undefined;
    const link = download ? `${process.env.REACT_APP_RES_URL}seminars/${file}` : undefined;

    const startDate = moment(start);
    const endDate = moment(end);
    let date;

    if (startDate.isSame(endDate)) {
      date = startDate.format('Do MMMM YYYY');
    } else {
      date = `${startDate.format('Do MMM')} to ${endDate.format('Do MMM YYYY')}`;
    }

    return (
      <a className='event-card' href={link} download={download}>
        {download && <i className='fas fa-download event__icon-link' />}
        <i className={backgroundIconCSS} />
        <p className='event-date'>{date}</p>
        <p className='event-title'>{title}</p>
      </a>
    );
  }
}

EventCard.propTypes = {
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  local: PropTypes.bool,
  grading: PropTypes.bool,
  file: PropTypes.string
};

export default EventCard;
