import * as ACTION_TYPES from './actionTypes';

function fetchDojosSuccess(dojos) {
  return {
    type: ACTION_TYPES.AT_DOJOMAP_GET_DOJOS_SUCCESS,
    dojos
  };
}

function fetchDojosFailure(error) {
  return {
    type: ACTION_TYPES.AT_DOJOMAP_GET_DOJOS_FAILURE,
    error
  };
}

export function getDojos() {
  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_API_URL}dojos`)
      .then(res => res.json())
      .then(res => {
        if (res.status === false) {
          throw res;
        }

        dispatch(fetchDojosSuccess(res.results));
      })
      .catch(error => {
        dispatch(fetchDojosFailure(error));
      });
  };
}
