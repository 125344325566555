import './affiliate.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class AffiliateCard extends PureComponent {
    render() {
      return (
        <div className='affiliate-card'>
          {this.props.backgroundImage && <img className='affiliate-background' src={this.props.backgroundImage} alt='' />}
          <div className='affiliate-header'>
            <h5>{this.props.header}</h5>
            <p className='affiliate-subheader'>{this.props.subheader}</p>
          </div>
          <div className='affiliate-info'>
            {this.props.contact && <p className='affiliate-detail'>{this.props.contact}</p>}
            {this.props.phone && <p className='affiliate-detail'>{this.props.phone}</p>}
            {this.props.email && <p className='affiliate-link'><a href={`mailto:${this.props.email}`}>{this.props.email}</a></p>}
            {this.props.website && <p className='affiliate-link'><a href={`http://${this.props.website}`} target='_blank' rel='noopener noreferrer'>{this.props.website}</a></p>}
          </div>
        </div>
      );
    }
}

AffiliateCard.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  contact: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  website: PropTypes.string,
  backgroundImage: PropTypes.string
};

export default AffiliateCard;
