import './banner.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Banner extends PureComponent {
  render() {
    const imageCSS = `banner-image${this.props.fitViewport ? ' banner-image__viewport': ''}`;

    return (
      <div className='banner'>
        <div className={imageCSS} style={{backgroundImage: `url('${this.props.image}')`}} />
        {this.props.title && <h1 className='banner__title'>{this.props.title}</h1>}
        {this.props.hasScrollIcon && <i className='fas fa-chevron-down scroll-icon' />}
        {this.props.hasScrollIcon && <p className='scroll-icon__text'>scroll down</p>}
        {this.props.children}
      </div>
    );
  }
}

Banner.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  hasScrollIcon: PropTypes.bool,
  fitViewport: PropTypes.bool
}

export default Banner;
