import React from 'react';
import moment from 'moment';

const Copyright = (props) => {
  return (
    <p className='copyright'>{`© 2019 - ${moment().format('YYYY')} Goshin Ju-Jitsu. All rights reserved.`}</p>
  );
};

export default Copyright;
