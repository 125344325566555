import './affiliates.css';
import React, { PureComponent } from 'react';
import { SectionBlock } from '../../../components/section';
import { AffiliateCard } from '../../../components/card';

import suomenLogo from '../../../images/affiliate-suomen_jujutsuliitto.png';
import kielLogo from '../../../images/affiliate-kiel_judo.png';
import jindokaiLogo from '../../../images/affiliate-jindokai.png';
import jindokaiSouthPacificLogo from '../../../images/affiliate-jindokai_south-pacific_white.png';
import jushinkanLogo from '../../../images/affiliate-jushinkan.png';

class Affiliates extends PureComponent {
  render() {
    return (
      <SectionBlock>
        <div className='affiliate-container'>
          <AffiliateCard header='Seidokan Karate' subheader='Pennsylvania, USA' contact='Sensei Bill Bratina & Ben Rocuskie' website='www.seidokanharrisburg.com' />
          <AffiliateCard header='Seidokan Karate' subheader='Florida, USA' contact='Sensei Paul Sadwick' website='seidokan-karate.com' />
          <AffiliateCard header='Westfield Goju Karate' website='sites.google.com/view/westfieldgojukarate/home' />
          <AffiliateCard header='Shinken Dojo' website='okinawakarateuk.com' />
          <AffiliateCard header='Kotokukan Budo Rengoka' website='www.kotokukandojo.org.uk' />
          <AffiliateCard header='Akido Research Federation' website='aikidoresearchfederation.co.uk' />
          <AffiliateCard header='Akido Seishin kai Stoke' website='www.aikidostoke.co.uk' />
          <AffiliateCard header='Seijitsu Akido Ryu' phone='REQUIRE NEW DETAILS' />
          <AffiliateCard header='Aiki Jutsu Club' subheader='Felixstowe & Ipswich' website='www.aikijutsufelixstowe.co.uk' />
          <AffiliateCard header='Jushinko Doju' contact='Hanshi Brian Rogers (8th Dan)' website='www.jushinkandojo.co.uk' backgroundImage={jushinkanLogo} />
          <AffiliateCard header='Dr. Stephen Chan' subheader='OBE, MA, BA, PhD' phone='+44 (0) 7764-768-639' website='www.stephen-chan.com' />
          <AffiliateCard header='TKI (Toku-Kai International)' contact='Shihan Ed Stiltner (9th Dan)' phone='(815) 335 3118' email='ed.stiltner@twcsite.net' />
          <AffiliateCard header='Suomen Ju Jutso Liitto' contact='Prof. D. Connelly (10th Dan)' email='cob@jujutsuliitto.fi' website='www.jujutsuliitto.fi' backgroundImage={suomenLogo} />
          <AffiliateCard header='Connelly Ju Jitsu' contact='Prof. D. Connelly (10th Dan)' website='www.connellyjujitsu.de' />
          <AffiliateCard header='SAS Martial arts' contact='Marie O’Leary' email='marie.is@live.co.uk' />
          <AffiliateCard header='S.A.S Martial Arts Academy' contact='Sifu Andrew Sofos' phone='0800 3287-086' website='www.sas-martialarts.co.uk' />
          <AffiliateCard header='ASF Training' contact='Sensei Scott & Amiee Fairborn' website='www.asftraining.co.uk' />
          <AffiliateCard header='Wing Chun MA Association' contact='Master Andrew Sofos' website='www.saswingchun.com' />
          <AffiliateCard header='Layton Ju Jitsu Club' contact='Shihan Steve Blackett (6th Dan)' phone='+44 (0) 7866-975-191' website='native.cabanova.co.uk' />
          <AffiliateCard header='In Yo Ju Jitsu' contact='Shihan Mark Hudson (7th Dan)' phone='+44 (0) 7771-617-848' website='www.inyojujitsu.co.uk' />
          <AffiliateCard header='GTC Studio' contact='George Mckenzie' phone='02073 728580' website='www.gtcstudio.com' />
          <AffiliateCard header='Escrima Concepts UK' contact='Master Steve Tappin' website='www.escrimaconcepts.com' />
          <AffiliateCard header='Kiel Judo Club' contact='Jochen Mueller' website='www.judo-klub-kiel.de' backgroundImage={kielLogo} />
          <AffiliateCard header='Dueren Ju Jutsu Club' contact='Sensei Dieter Fub' website='www.jujutsu-dueren.de' />
          <AffiliateCard header='Clubbz' website='www.clubbz.com' />
          <AffiliateCard header='Sports Coaching' contact='Kyoshi Jason Fitzpatrick' website='www.ukcoaching.org' />
          <AffiliateCard header='Jindokai' contact='Sensei Eddie' website='www.jindokai.co.uk' backgroundImage={jindokaiLogo} />
          <AffiliateCard header='Jindokai' subheader='South Pacific' contact='Sensei Andy Roosen' website='www.kokenryu.com' backgroundImage={jindokaiSouthPacificLogo} />
          <AffiliateCard header='Black Belt Hall of Fame' subheader='United States of America' phone='(001)800 456-3872' website='www.eusaimaa.com' />
          <AffiliateCard header='Margate Karate Club' contact='Sensei Rose Curtis (4th Dan)' website='www.margatekarateclub.co.uk' />
        </div>
      </SectionBlock>
    );
  }
}

export default Affiliates;
