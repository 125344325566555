import './about.css';
import React, { PureComponent } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Banner from '../../components/banner';
import Section from '../../components/section';
import { Affiliates, Club, Instructors } from './tabs';
import Page from '../../components/page';

import bannerBackground from '../../images/nicks-club-blur.jpg';

class About extends PureComponent {
  render() {
    return (
      <Page title='ABOUT | Goshin Ju-Jitsu'>
        <Banner image={bannerBackground} title='ABOUT' />
        <Section>
          <Tabs id='about-tab-controller'>
            <Tab title='CLUB' eventKey={1}>
              <Club />
            </Tab>
            <Tab title='INSTRUCTORS' eventKey={2}>
              <Instructors />
            </Tab>
            <Tab title='AFFILIATES' eventKey={3}>
              <Affiliates />
            </Tab>
          </Tabs>
        </Section>
      </Page>
    );
  }
}

export default About;
