import './schedule.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Schedule extends PureComponent {
  render() {
    return (
      <div>
        <h4 className='schedule-title'>{this.props.title}</h4>
        <h6 className='schedule-subtitle'>{this.props.subtitle}</h6>
        <div className='schedule'>
          {this.props.children}
        </div>
      </div>
    );
  }
}

Schedule.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default Schedule;
