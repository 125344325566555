import './home.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Section, { SectionBlock } from '../../components/section';
import { Card, CardContainer, EventCard } from '../../components/card';
import GoshinLogo from '../../components/logo';
import Banner from '../../components/banner';
import Page from '../../components/page';
import DojoMapContainer from '../../containers/dojo-map';

import cardJunior from '../../images/card-junior-m.jpg';
import cardAdult from '../../images/card-adult-m.jpg';
import cardPrivate from '../../images/card-private-m.jpg';
import bannerImage from '../../images/banner-home.jpg';

class Home extends PureComponent {
  renderSeminars = () => {
    const { seminars } = this.props;

    return (
      seminars.map(event => <EventCard key={event.title+event.start} {...event} />)
    );
  }

  render() {
    return (
      <Page title='HOME | Goshin Ju-Jitsu'>
        <Banner image={bannerImage} fitViewport hasScrollIcon>
          <div className='banner-textbox'>
            <GoshinLogo large />
            <p className='banner-slogan b-honour'>HONOUR.</p>
            <p className='banner-slogan b-discipline'>DISCIPLINE.</p>
            <p className='banner-slogan b-respect'>RESPECT.</p>
          </div>
        </Banner>
        <Section>
          <SectionBlock header='UPCOMING SEMINARS'>
            <CardContainer emptyLabel='No seminars booked...'>
              {this.renderSeminars()}
            </CardContainer>
          </SectionBlock>
          <SectionBlock header='OUR DOJOs'>
            <DojoMapContainer mapCSS='home__map' maxBounds={[[52,-0.7],[51.5,0.3]]} center={[51.74, -0.07]} zoom={10} />
          </SectionBlock>
          <SectionBlock header='TRAINING'>
            <CardContainer>
              <Card title='JUNIOR' link='/junior' image={cardJunior} />
              <Card title='ADULT' link='/adult' image={cardAdult} />
              <Card title='PRIVATE' link='/private' image={cardPrivate} />
            </CardContainer>
          </SectionBlock>
        </Section>
      </Page>
    );
  }
}

Home.propTypes = {
  seminars: PropTypes.array.isRequired
};

export default Home;
