import './image.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Image extends PureComponent {
  render() {
    return (
      <div className='image-container'>
        <img className='image-display' alt={this.props.imageAlt} src={this.props.image} />
        <p className='image-caption'>{this.props.caption}</p>
      </div>
    );
  }
}

Image.propTypes = {
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  caption: PropTypes.string
};

export default Image;
