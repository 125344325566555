import * as Utils from '../../utils';
import * as ACTION_TYPES from './actionTypes';

function fetchSeminarsSuccess(seminars) {
  return {
    type: ACTION_TYPES.AT_HOME_GET_SEMINARS_SUCCESS,
    seminars
  };
}

function fetchSeminarsFailure(error) {
  return {
    type: ACTION_TYPES.AT_HOME_GET_SEMINARS_FAILURE,
    error
  };
}

export function getSeminars() {
  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_API_URL}seminars`)
      .then(res => res.json())
      .then(res => {
        if (res.status === false) {
          throw res;
        }

        // TODO: Another action or promise ?
        const data = Utils.filterOutOfDate(res.results);
        data.sort(Utils.sortDatesDescending);

        dispatch(fetchSeminarsSuccess(data));
      })
      .catch(error => {
        dispatch(fetchSeminarsFailure(error));
      });
  };
}
