import './sectionBlock.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SectionBlock extends PureComponent {
  render() {
    const { header, blockCSS } = this.props;

    return (
      <div className='section-block'>
        { header &&
          <div className='section-header'>
            <h2>{ header }</h2>
          </div> }
        <div className={`section-body ${blockCSS}`}>
          { this.props.children }
        </div>
      </div>
    );
  }
}

SectionBlock.propTypes = {
  header: PropTypes.string,
  blockCSS: PropTypes.string
};

export default SectionBlock;
