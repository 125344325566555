import './gallery.css';
import React, { PureComponent } from 'react';
import Section, { SectionBlock } from '../../components/section';
import GalleryItem from '../../components/gallery-item';
import ImageModal from '../../components/image-modal';
import Banner from '../../components/banner';
import Page from '../../components/page';

import bannerBackground from '../../images/banner-gallery.jpg';
import galleryImages from '../../images/gallery';

class Gallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      scrollPosition: 0
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = () => {
    this.setState({scrollPosition: window.scrollY});
  };

  onSelectGalleryItem = (imgIndex) => {
    this.setState({showModal: true, image: galleryImages[imgIndex]});
  };

  onCloseModal = () => {
    this.setState({showModal: false});
  };

  renderThumbnails = () => galleryImages.map((image, index) =>
    <GalleryItem forceLoad={index < 20} id={index} key={index} image={image} onClick={this.onSelectGalleryItem} scrollPosition={this.state.scrollPosition} />
  );

  render() {
    const { showModal, image } = this.state;
    return (
      <Page title='GALLERY | Goshin Ju-Jitsu'>
        <Banner image={bannerBackground} title='GALLERY' />
        <Section>
          <SectionBlock>
            <div className='gallery-container'>
              {this.renderThumbnails()}
            </div>
          </SectionBlock>
        </Section>
        <ImageModal show={showModal} image={image} onClose={this.onCloseModal} />
      </Page>
    );
  }
}

export default Gallery;
