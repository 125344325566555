import './version.css';
import React, { PureComponent } from 'react';
import { version } from '../../package.alias.json';

class Version extends PureComponent {
  render() {
    return (
      <p className='version'>{`v${version}`}</p>
    );
  }
}

export default Version;
