import React, { PureComponent } from 'react';
import Banner from '../../components/banner';
import Section, { SectionBlock } from '../../components/section';
import GoshinLogo from '../../components/logo';
import Page from '../../components/page';

import adultBanner from '../../images/adult-banner.jpg';

class Adult extends PureComponent {
  render() {
    return (
      <Page title='ADULT TRAINING | Goshin Ju-Jitsu'>
        <Banner image={adultBanner} fitViewport hasScrollIcon>
          <div className='training-banner-textbox'>
            <GoshinLogo />
          </div>
        </Banner>
        <Section>
          <SectionBlock header='GOSHIN ADULTS'>
            <p>&nbsp;Training with Goshin Ju Jitsu will teach you life skills that may not only protect the lives of yourself and your family but, give you skills for your career.</p>
            <p>&nbsp;If you&#39;ve ever wondered what you could achieve mentally and/or physically, Goshin Ju Jitsu can strengthen your mind and body and push you beyond your limits, ultimately learning who you really are and what you can achieve!</p>
            <p>&nbsp;With hard Goshin training you can gain:</p>
            <ul>
              <li>Improved fitness and stamina</li>
              <li>Increase in self-confidence and self-respect</li>
              <li>Leadership skills through teaching lower grades as you progress</li>
              <li>Better concentration in performing mental tasks</li>
              <li>Excellent performance under pressure</li>
            </ul>
            <p>&nbsp;Along with the above benefits Goshin is more martial than art and will teach you excellent survival skills in handling confrontations and physical assualts.</p>
            <p>&nbsp;Unlike other martial art clubs be prepared for intense physical activity, it is hard training that will benefit you in the long run! So stick at it!</p>
            <p>&nbsp;If you are struggling or desire further intensive training, don&#39;t fear asking for private tuition from Kyoshi Jason. Ask in the dojo or call <a href='tel:+447763203948'>+44 (0) 7763 203-948</a>.</p>
          </SectionBlock>
        </Section>
      </Page>
    );
  }
}

export default Adult;
