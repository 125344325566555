import './achievement.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Achievement extends PureComponent {
  onClick = () => {
    this.props.onClick(this.props.achievement.image);
  };

  render() {
    const { achievement } = this.props;

    const isObject = achievement instanceof Object;
    const hasImage = isObject && achievement.image;

    const title = isObject ? achievement.title : achievement;
    const aCSS = `achievement${hasImage ? ' selectable' : ''}`;

    return (
      <div className={aCSS} onClick={hasImage ? this.onClick : undefined}>
        <i className='fas fa-trophy' />
        <span className='achievement-text'>{title}</span>
        {hasImage && <i className='fas fa-external-link-alt achievement-link-icon' />}
      </div>
    );
  }
}

Achievement.props = {
  achievement: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default Achievement;
