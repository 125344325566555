import './galleryItem.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GalleryItem extends PureComponent {
  constructor(props) {
    super(props);
    this.itemRef = React.createRef();
    this.hasLoaded = false;
  }

  onClick = () => {
    this.props.onClick(this.props.id);
  };

  canLoad = () => {
    if(this.hasLoaded) return true;

    const { forceLoad } = this.props;
    if (forceLoad) return true;

    const itemRef = this.itemRef.current;
    if (!itemRef) return false;

    const { scrollPosition } = this.props;
    const threshold = window.innerHeight - 50;
    const load = (itemRef !== null) && (itemRef.offsetTop - threshold < scrollPosition);

    if (load) this.hasLoaded = true;

    return load;
  };

  render() {
    return (
      <div ref={this.itemRef} className='gallery-item' onClick={this.onClick}>
        {this.canLoad() && <img className='gallery-item__image' src={this.props.image} title={this.props.title} alt='' />}
      </div>
    );
  }
}

GalleryItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string,
  image: PropTypes.string,
  forceLoad: PropTypes.bool,
  scrollPosition: PropTypes.number,
  onClick: PropTypes.func
};

export default GalleryItem;
