import './unknown.css';
import React, { PureComponent } from 'react';
import GoshinLogo from '../../components/logo';
import Page from '../../components/page';

class Unknown extends PureComponent {
  render() {
    return (
      <Page title='404 | Goshin Ju-Jitsu'>
        <div className='unknown-bg'>
          <div className='unknown-block'>
              <h1 className='unknown-title'>404</h1>
              <GoshinLogo />
              <h2 className='unknown-text'>PAGE NOT FOUND</h2>
          </div>
        </div>
      </Page>
    );
  }
}

export default Unknown;
