import './instructor.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import Achievement from '../achievement';

class InstructorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBio: false
    };
  }

  onClick = (image) => {
    this.props.onClick(image);
  };

  createAchievementsTable = (achievementArray) => achievementArray.map(achievement => <Achievement key={uuid()} achievement={achievement} onClick={this.onClick} />);

  onExpandClick = () => {
    this.setState({showBio: !this.state.showBio});
  };

  render() {
    const { name, photo, achievements, bio, isSenior, isSecretary } = this.props;
    const { showBio } = this.state;

    const bioCSS = `bio${showBio ? ' show' : ''}`;
    const buttonCSS = `expand-bio-button${showBio ? ' selected' : ''}`;

    const title = `${isSecretary ? 'SECRETARY' : `${isSenior ? 'SENIOR' : 'JUNIOR'} INSTRUCTOR`}`;

    return (
      <div className='instructor-card'>
        <div className='instructor-identity'>
          {photo && <img className='instructor-photo' src={photo} alt={name} />}
          <h6>{title}</h6>
          <h4 className='instructor-name'>{name}</h4>
        </div>
        <div className='instructor-achievements'>
            {achievements && this.createAchievementsTable(achievements)}
        </div>
        {bio &&
          <div className='instructor-bio'>
            {<p className={bioCSS}>&nbsp;{bio}</p>}
          </div>}
        {bio &&
          <div className={buttonCSS} onClick={this.onExpandClick}>
            <i className='fas fa-angle-double-down fa-2x' />
          </div>}
      </div>
    );
  }
}

InstructorCard.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string,
  bio: PropTypes.string,
  achievements: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  onClick: PropTypes.func
};

export default InstructorCard;
