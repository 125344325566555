import './logo.css';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GoshinLogo extends PureComponent {
  render() {
    let logoClass = 'logo';

    if (this.props.large) {
      logoClass += ' logo-l';
    } else {
      logoClass += ' logo-m';
    }

    return (
      <div className={logoClass}>
        {this.props.large ? <h1 className='title-l'>GOSHIN</h1> : <h3 className='title-m'>GOSHIN</h3>}
        <p className={this.props.large ? 'subtitle-l' : 'subtitle-m'}>JU-JITSU</p>
      </div>
    );
  }
}

GoshinLogo.propTypes = {
  large: PropTypes.bool,
  medium: PropTypes.bool
};

export default GoshinLogo;
