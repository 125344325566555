import moment from 'moment';

export function sortDatesDescending(a, b) {
  if (moment(a.start).isBefore(moment(b.start))) return -1;
  if (moment(a.start).isAfter(moment(b.start))) return 1;
  else return 0;
}

export function filterOutOfDate(array) {
  const now = moment();
  return array.filter(item => moment(item.end).isSameOrAfter(now));
}
